import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form, TextField, FormActionsBtns, EditCard, Select,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import typeAuthority from '../typeAuthority';

export default React.memo( ( {
  data, parentPath, onSubmit,
} ) => (
  <EditCard
    cardClass="card-sec-bottom"
  >
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      showErrorsInFooter
      body={() => (
        <>
          <Row>
            <Col md={4}>
              <Select
                field="type"
                label="type"
                options={typeAuthority}
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                field="username"
                label="username"
                validate={required}
              />
            </Col>
            <Col md={3}>
              <TextField
                field="password"
                label="password"
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                field="propertyCode"
                label="propertyCode"
                validate={required}
              />
            </Col>
            <Col md={3}>
              <TextField
                field="propertyName"
                label="propertyName"
                validate={required}
              />
            </Col>
            <Col md={3}>
              <TextField
                field="reportFileName"
                label="reportFileName"
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
      footer={canModify( 'accommodation' ) && <FormActionsBtns cancelTo={parentPath} />}
    />
  </EditCard>
) );
