import React, { useState } from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router';
import classnames from 'classnames';
import { SimpleHeader, HorizontalMenu, Intl } from 'components';
import BookingCalendar from 'containers/Property/RoomType/Accommodation/Edit/Bookings/Calendar';

export default React.memo(
  ( {
    data,
    entityId,
    isLoading,
    propertyName,
    roomTypeName,
    parentIds,
    tabs,
    paths,
    pathLayout,
    pathname,
    onReloadData,
  } ) => {
    const [showModal, setShowModal] = useState( false );

    const toggleModal = () => setShowModal( !showModal );

    const getRoutes = ( routesList ) => routesList.map( ( route ) => {
      if ( route.component ) {
        return (
          <Route
            exact={route.exact}
            path={pathLayout + route.path}
            render={( props ) => (
              <route.component
                {...props}
                data={data}
                entityId={entityId}
                parentIds={parentIds}
                paths={paths}
                extraData={route.extraData}
                onReloadData={onReloadData}
                pathname={pathname}
                pathLayout={pathLayout}
              />
            )}
            key={route.layout + route.path}
          />
        );
      }
      return null;
    } );

    const breadcrumb = [];
    if ( paths.property ) {
      breadcrumb.push( {
        name: propertyName,
        translate: false,
        href: paths.property,
      } );
    }
    if ( paths.property ) {
      breadcrumb.push( {
        name: roomTypeName,
        translate: false,
        href: paths.roomType,
      } );
    }

    return (
      <>
        <SimpleHeader
          parentLinks={[
            // ...breadcrumb,
            { name: 'buildings', href: paths.property },
          ]}
          rightContent={(
            entityId ? (
              <Button
              // size="sm"
                color={showModal ? 'secondaryColor' : 'primary'}
                className="mt-2 mt-md-0 mx-2"
                onClick={toggleModal}
              >
                <i className="fas fa-file-invoice mr-1" />
                <Intl id="ru.settings.calendar.show" />
              </Button>
            ) : null
          )}
        />

        <Container
          fluid
          className={classnames( {
            'app-component-loading': isLoading,
          } )}
        >
          {!!entityId && (
            <Card className="card-sec-top border-bottom">
              <HorizontalMenu
                tabs={tabs}
                pathLayout={pathLayout}
                pathname={pathname}
              />
            </Card>
          )}
          <Row>
            <Col>
              <Switch>
                {getRoutes( tabs )}
                <Redirect from="*" to={paths.accommodation} />
              </Switch>
            </Col>
          </Row>
        </Container>
        {entityId
          ? (
            <BookingCalendar
              entityId={entityId}
              parentIds={parentIds}
              modalCalendarOpen={showModal}
              toggleCalendarModal={toggleModal}
            />
          ) : null }
      </>
    );
  },
);
