import React from 'react';

import {
  Badge,
} from 'reactstrap';

import { CopyToClipboard } from 'components';

const BadgeClip = ( { text } ) => (
  <li>
    <CopyToClipboard text={text}>
      <Badge color="light" className="border mb-2 mr-2 text-darker">
        {text}
      </Badge>
    </CopyToClipboard>
  </li>
);

export default BadgeClip;
