export default [
  { id: 'A_Coruña', name: 'A_Coruña' },
  { id: 'Álava', name: 'Álava' },
  { id: 'Albacete', name: 'Albacete' },
  { id: 'Alicante', name: 'Alicante' },
  { id: 'Almería', name: 'Almería' },
  { id: 'Asturias', name: 'Asturias' },
  { id: 'Ávila', name: 'Ávila' },
  { id: 'Badajoz', name: 'Badajoz' },
  { id: 'Barcelona', name: 'Barcelona' },
  { id: 'Bizkaia', name: 'Bizkaia' },
  { id: 'Burgos', name: 'Burgos' },
  { id: 'Cáceres', name: 'Cáceres' },
  { id: 'Cádiz', name: 'Cádiz' },
  { id: 'Cantabria', name: 'Cantabria' },
  { id: 'Castellón', name: 'Castellón' },
  { id: 'Ceuta', name: 'Ceuta' },
  { id: 'Ciudad_Real', name: 'Ciudad_Real' },
  { id: 'Córdoba', name: 'Córdoba' },
  { id: 'Cuenca', name: 'Cuenca' },
  { id: 'Girona', name: 'Girona' },
  { id: 'Granada', name: 'Granada' },
  { id: 'Guadalajara', name: 'Guadalajara' },
  { id: 'Gipuzkoa', name: 'Gipuzkoa' },
  { id: 'Huelva', name: 'Huelva' },
  { id: 'Huesca', name: 'Huesca' },
  { id: 'Baleares', name: 'Baleares' },
  { id: 'Jaén', name: 'Jaén' },
  { id: 'Las_Palmas', name: 'Las_Palmas' },
  { id: 'León', name: 'León' },
  { id: 'Lleida', name: 'Lleida' },
  { id: 'Lugo', name: 'Lugo' },
  { id: 'Madrid', name: 'Madrid' },
  { id: 'Málaga', name: 'Málaga' },
  { id: 'Melilla', name: 'Melilla' },
  { id: 'Murcia', name: 'Murcia' },
  { id: 'Navarra', name: 'Navarra' },
  { id: 'Ourense', name: 'Ourense' },
  { id: 'Palencia', name: 'Palencia' },
  { id: 'Pontevedra', name: 'Pontevedra' },
  { id: 'La_Rioja', name: 'La_Rioja' },
  { id: 'Salamanca', name: 'Salamanca' },
  { id: 'Santa_Cruz_de_Tenerife', name: 'Santa_Cruz_de_Tenerife' },
  { id: 'Segovia', name: 'Segovia' },
  { id: 'Sevilla', name: 'Sevilla' },
  { id: 'Soria', name: 'Soria' },
  { id: 'Tarragona', name: 'Tarragona' },
  { id: 'Teruel', name: 'Teruel' },
  { id: 'Toledo', name: 'Toledo' },
  { id: 'Valencia', name: 'Valencia' },
  { id: 'Valladolid', name: 'Valladolid' },
  { id: 'Zamora', name: 'Zamora' },
  { id: 'Zaragoza', name: 'Zaragoza' },
];
