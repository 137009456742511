import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DashboardView from 'views/PropertyManager/Edit/Contract/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { useFetchTableData } from 'hooks';
import ContractService from 'api/Settings/Contract';
import useDelete from 'hooks/useDelete';

const Dashboard = ( {
  toggleErrorAlert,
  propertyManager,
  toggleLoading,
  parentPath,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => ContractService.getContractSettings( {
      ...params,
      propertyManager: propertyManager.id,
    } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
  } );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deleteContractSetting = useDelete( {
    promise: ContractService.deleteContractSetting,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  return (
    <DashboardView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDelete={deleteContractSetting}
      parentPath={parentPath}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
