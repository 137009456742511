// import _ from 'lodash';

import General from 'containers/Property/RoomType/Accommodation/Edit/General';
import AmenityInventory from 'containers/Property/RoomType/Accommodation/Edit/AmenityInventory/Dashboard';
import AmenityRecord from 'containers/Property/RoomType/Accommodation/Edit/AmenityRecords/Dashboard';
// import CustomField from 'containers/Property/RoomType/Accommodation/Edit/CustomField';
import RoomExpenses from 'containers/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard';
import ArrivalInstructions from 'containers/Property/RoomType/Accommodation/Edit/ArrivalInstructions';
import Rates from 'containers/Property/RoomType/Accommodation/Edit/Rates/Dashboard';
// import Bookings from 'containers/Property/RoomType/Accommodation/Edit/Bookings/Dashboard';
// import ArrivalInstructions
// from 'containers/Property/RoomType/Accommodation/Edit/ArrivalInstructions';
// import CheckInOut from 'containers/Property/RoomType/Accommodation/Edit/CheckInOut';
// import CancellationPollicies
// from 'containers/Property/RoomType/Accommodation/Edit/CancellationPolicies';
import RUInfo from 'containers/Property/RoomType/Accommodation/Edit/RUInfo';
import SettingsApp from 'containers/Property/RoomType/Accommodation/Edit/SettingsApp';
// import Landlord from 'containers/Property/RoomType/Accommodation/Edit/Landlord';
// import Rates from 'containers/Property/RoomType/Accommodation/Edit/Rates/Dashboard';
// import Discounts from 'containers/Property/RoomType/Accommodation/Edit/Discounts/Dashboard';
// import Occupancies from 'containers/Property/RoomType/Accommodation/Edit/Occupancies/Dashboard';
// import Bookings from 'containers/Property/RoomType/Accommodation/Edit/Bookings/Dashboard';
// import Rates from 'containers/Property/RoomType/Accommodation/Edit/Rates/Dashboard';
// import Discounts from 'containers/Property/RoomType/Accommodation/Edit/Discounts/Dashboard';
// import Occupancies from 'containers/Property/RoomType/Accommodation/Edit/Occupancies/Dashboard';
// import Bookings from 'containers/Property/RoomType/Accommodation/Edit/Bookings/Dashboard';

export default [
  {
    name: 'general',
    path: '',
    component: General,
    exact: true,
  },
  // TODO: PMS
  {
    name: 'ru.settings',
    path: '/ru-info',
    component: RUInfo,
  },
  {
    name: 'rate.settings',
    path: '/rates',
    component: Rates,
  },
  {
    name: 'settings.app.acc',
    path: '/settings-app',
    component: SettingsApp,
  },
  // {
  //   name: 'smartLockerLegacy',
  //   path: '/smart-locker-legacy',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: ( { category } ) => category === 'smartlockers',
  //     title: 'smartLocker',
  //   },
  // },
  // {
  //   name: 'smartLocker',
  //   path: '/smart-locker',
  //   component: Smartlockers,
  //   extraData: {
  //     filter: ( { category } ) => category === 'smartlockers',
  //     title: 'smartLocker',
  //   },
  // },
  // {
  //   name: 'Wifi',
  //   path: '/wifi',
  //   component: Wifi,
  // },
  // {
  //   name: 'travelPlugin',
  //   path: '/travel-plugin',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: { category: 'estr' },
  //     title: 'travelPlugin',
  //   },
  // },
  // {
  //   name: 'travelReport',
  //   path: '/travelReport',
  //   component: TravelReport,
  // },
  {
    name: 'expenses',
    path: '/expenses',
    component: RoomExpenses,
  },
  {
    name: 'inventory',
    path: '/inventory',
    component: AmenityInventory,
  },
  {
    name: 'amenityRecords',
    path: '/amenity-records',
    component: AmenityRecord,
  },
  {
    name: 'arrivalInstructions',
    path: '/arrivalInstructions',
    component: ArrivalInstructions,
  },
  // TODO: PMS
  // {
  //   name: 'rates',
  //   path: '/rates',
  //   component: Rates,
  // },
  // {
  //   name: 'discounts',
  //   path: '/discounts',
  //   component: Discounts,
  // },
  // {
  //   name: 'occupancies',
  //   path: '/occupancies',
  //   component: Occupancies,
  // },
  // {
  //   name: 'bookings',
  //   path: '/bookings',
  //   component: Bookings,
  // },
  //-----
  // {
  //   name: 'customFields',
  //   path: '/custom-fields',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: ( item ) => !_.startsWith( item.name, 'homeselect_communityRule' )
  //       && item.category !== 'estr'
  //       && item.category !== 'smartlockers',
  //     title: 'customFields',
  //   },
  // },
];
