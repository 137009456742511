import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
// import async from 'async';

import AccommodationEditView from 'views/Property/RoomType/Accommodation/Edit';
import SettingsActions from 'store/reducers/Settings';
// import { formatCustomValues, handlePromise } from 'utils';
import { handlePromise } from 'utils';
import AccommodationService from 'api/Accommodation';
// import CustomFieldService from 'api/Settings/CustomField';
import { accommodationRoutes } from 'config/Routes';
import { waterfall } from 'utils/async';
import LandlordsService from 'api/AccommodationLandlords';

const AccommodationEdit = ( {
  propertyData,
  roomTypeData,
  propertyManager,
  parentIds,
  paths,
  match,
  location,
  toggleErrorAlert,
  ruInstalled,
} ) => {
  const entityId = match.params.id;
  const [routesList, setRoutesList] = useState( accommodationRoutes );
  const [data, setData] = useState( {} );
  const [dataLoading, setDataLoading] = useState( true );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const getEntityData = useCallback(
    async ( cb ) => {
      if ( !entityId ) return cb( null, {} );
      const [errors, response, responseData] = await handlePromise(
        AccommodationService.getGenericAccommodation( entityId ),
      );

      if ( !response.ok ) return cb( errors );
      return cb( null, { entity: { ...responseData } } );
    },
    [entityId],
  );

  const getLandlord = useCallback(
    async ( entityData, cb ) => {
      if ( !propertyManager || !propertyManager.id ) return cb( 'apiError' );
      const [errors, response, responseData] = await handlePromise(
        LandlordsService.getAccomodationLandlords( {
          page: 1,
          elementsPerPage: 10,
          accommodation: entityData.entity.id,
        } ),
      );
      if ( !response.ok ) return cb( errors );
      if ( responseData.data ) {
        return cb( null, {
          entity: { ...entityData.entity, landlord: responseData.data[0] },
        } );
      }

      return cb( null, {
        entity: { ...entityData },
      } );
    },
    [propertyManager],
  );

  useEffect( () => {
    const checkForRU = async () => {
      if ( ruInstalled ) {
        const _accommodationRoutes = accommodationRoutes.filter(
          ( item ) => item.name !== 'arrivalInstructions',
        );
        setRoutesList( _accommodationRoutes );
      }
    };
    checkForRU();
  }, [ruInstalled] );

  useEffect( () => {
    setDataLoading( true );
    if ( !entityId ) {
      setDataLoading( false );
      return;
    }

    waterfall(
      [
        getEntityData,
        getLandlord,
        // getCustomFields,
      ],
      ( errors, results ) => {
        setDataLoading( false );
        if ( errors ) {
          setDataLoading( false );
          return toggleErrorAlert( errors );
        }

        const isOlaliving = propertyManager.name.toLowerCase() === 'olaliving';

        if ( !entityId ) setRoutesList( [accommodationRoutes[0]] );
        else {
          const routeListFiltered = isOlaliving
            ? [...accommodationRoutes]
            : accommodationRoutes.filter(
              ( route ) => route.name !== 'smartLockerLegacy',
            );
          // _.map( accommodationRoutes, ( item ) => {
          //   if ( _.get( item, 'extraData.type' ) ===
          // 'customFields' && _.get( item, 'extraData.filter' ) ) {
          //     if ( !_.filter( results.customFields, item.extraData.filter ).length ) {
          //       routeListFiltered = _.filter(
          //         routeListFiltered, ( route ) => route.name !== item.name,
          //       );
          //     }
          //   }
          // } );
          if ( routeListFiltered.length !== routesList.length ) setRoutesList( routeListFiltered );
        }
        setData( results );
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, dataSubmitted, toggleErrorAlert, getEntityData] );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  return (
    <AccommodationEditView
      data={data}
      entityId={entityId}
      propertyName={propertyData ? propertyData.name : null}
      roomTypeName={roomTypeData ? roomTypeData.name : null}
      parentIds={
        parentIds
        || ( _.get( data, 'entity.id' )
          ? {
            roomType: _.get( data, 'entity.roomType.id' ),
            property: _.get( data, 'entity.roomType.property.id' ),
          }
          : {} )
      }
      tabs={routesList}
      paths={paths}
      pathLayout={match.url}
      pathname={location.pathname}
      isLoading={dataLoading}
      onReloadData={reloadData}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  ruInstalled: _.get( user, 'settings.ruInstalled', false ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( AccommodationEdit );
