import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import BookingOverviewView from 'views/Booking/Edit/Overview';
import BookingService from 'api/Booking';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import PluginService from 'api/Plugin';
import AvantioService from 'api/Avantio';

import { useFetch } from 'hooks';
import GuestModalForm from './GuestModalForm';
import MaxGuestsModal from '../../../../views/Booking/Edit/Overview/MaxGuestsModal';

const BookingOverview = ( {
  bookingData = {},
  onReloadData,
  toggleLoading,
  toggleErrorAlert,
  toggleInfoAlert,
  userBookingReference,
} ) => {
  const {
    id: accommodationId, booking = {}, localizator, externalId, propertyManager,
  } = bookingData;

  const bookingId = booking.id;
  const propertyManagerId = _.get( propertyManager, 'id' );
  const [openModalGuest, setOpenModalGuest] = useState( false );
  const [openModalMaxGuest, setOpenModalMaxGuest] = useState( false );
  const [selectedGuest, setSelectedGuest] = useState();

  const closeMaxModal = () => setOpenModalMaxGuest( false );

  const [isAvantioActive] = useFetch( {
    initialState: {},
    promise: () => PluginService.getPlugins( {
      installed: 'true',
    } ),
    format: ( dataToFormat ) => {
      const avantio = Object.values( _.get( dataToFormat, 'data', {} ) ).find( ( { key } ) => key === 'avantio' );
      return !!avantio;
    },
    toggleErrorAlert,
    reInit: true,
  } );

  const submitFieldForm = useCallback(
    async ( {
      checkInTime,
      checkOutTime,
      withIssues,
      allowCheckinWithoutPaymentComplete,
      notPaymentNeeded,
      status,
    } ) => {
      toggleLoading( true );
      const formattedData = {
        status,
        withIssues: withIssues ? 'true' : 'false',
        allowCheckinWithoutPaymentComplete: allowCheckinWithoutPaymentComplete
          ? 'true'
          : 'false',
        notPaymentNeeded: notPaymentNeeded ? 'true' : 'false',
      };
      if ( checkInTime && checkInTime.format ) {
        formattedData.checkInTime = checkInTime.format( 'HH:mm' );
      }
      if ( checkOutTime && checkOutTime.format ) {
        formattedData.checkOutTime = checkOutTime.format( 'HH:mm' );
      }
      // eslint-disable-next-line no-unused-vars
      const [errors, response] = await handlePromise(
        BookingService.updateBooking( bookingId, accommodationId, formattedData ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        onReloadData();
        return toggleErrorAlert( 'apiError' );
      }
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [toggleErrorAlert, toggleInfoAlert, toggleLoading, accommodationId, bookingId, onReloadData],
  );

  const onOpenNewGuestModal = ( ) => {
    if ( bookingData.capacity <= bookingData.guests.length ) {
      setOpenModalMaxGuest( true );
    } else {
      setOpenModalGuest( true );
      setSelectedGuest();
    }
  };

  const onOpenEditGuestModal = ( guest ) => {
    setOpenModalGuest( true );
    setSelectedGuest( guest );
  };

  const handleSyncAvantio = useCallback(
    async ( ) => {
      toggleLoading( true );
      const [errors, response] = await handlePromise(
        AvantioService.sync( { localizator, externalId, propertyManagerId } ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( errors );
      }
      onReloadData();
      toggleLoading( false );
      return toggleInfoAlert( 'dataSync' );
    },
    [localizator, externalId, propertyManagerId,
      onReloadData, toggleErrorAlert, toggleInfoAlert, toggleLoading],
  );

  return (
    <>
      <BookingOverviewView
        data={bookingData}
        onReloadData={onReloadData}
        onSubmit={submitFieldForm}
        userBookingReference={userBookingReference}
        handleOpenModalGuest={onOpenNewGuestModal}
        onEditGuest={onOpenEditGuestModal}
        isAvantioActive={isAvantioActive}
        onClickSync={handleSyncAvantio}
      />
      <GuestModalForm
        openModalGuest={openModalGuest}
        onCloseModalGuest={() => setOpenModalGuest( false )}
        bookedAccommodation={accommodationId}
        onReloadData={onReloadData}
        // eslint-disable-next-line max-len
        isOwner={bookingData.owner && selectedGuest && bookingData.owner.id === selectedGuest.user.id}
        selectedGuest={selectedGuest}
      />
      <MaxGuestsModal modalOpened={openModalMaxGuest} handleClose={closeMaxModal} />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  userBookingReference: _.get( user, 'settings.bookingReference' ),

} );
const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( BookingOverview );
