import React, { useCallback, useEffect, useState } from 'react';
import { AsyncSelect, Intl } from 'components';
import { Row, Col, Button } from 'reactstrap';

import ExtraExpenseService from 'api/ExtraExpenses';

const ExpensesList = ( {
  onSubmitExpenses, value = [], extraCosts, disabled,
} ) => {
  const [expenses, setExpenses] = useState( [] );
  // const [showForm, setShowForm] = useState( false );
  const fetchExtraExpenses = useCallback( ( query ) => ExtraExpenseService.getExtraExpenses( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  useEffect( () => {
    if ( value ) {
      setExpenses( value );
    }
  }, [value] );

  const handleChange = ( val ) => ( val ? setExpenses( val ) : setExpenses( [] ) );

  // const toggleForm = useCallback( () => setShowForm( !showForm ), [showForm] );

  const handleSubmit = () => {
    onSubmitExpenses( expenses, extraCosts );
  };

  return (
    <div>
      {disabled ? null : (
        <Row>
          <Col>
            <AsyncSelect
              label="availableCostTemplates"
              value={expenses}
              multiple
              defaultOptions
              isClearable
              onChange={handleChange}
              loadOptions={fetchExtraExpenses}
            />
          </Col>
          <Col className="pt-421">
            <Button
              color="primary"
              className="text-uppercase text-xsm"
              onClick={handleSubmit}
            >
              <Intl id={expenses.length ? 'addExpenses' : 'removeExpenses'} />
            </Button>
          </Col>
        </Row>
      )}

    </div>
  );
};

// const ExpenseForm = ( {
//   currentExpenses, toggleForm, onSubmit, extraCosts,
// } ) => {
//   const intitialValues = {
//     name: '',
//     description: '',
//     cost: 0,
//   };

//   const [values, setValues] = useState( intitialValues );

//   const setProperty = ( value, key ) => setValues(
//     ( prev ) => ( {
//       ...prev,
//       [key]: value,
//     } ),
//   );

//   const validateProperties = () => {
//     // eslint-disable-next-line no-restricted-syntax
//     for ( const key in values ) {
//       if (
//         Object.prototype.hasOwnProperty.call( values, key )
//         && Boolean( values[key] ) !== true
//       ) {
//         return false;
//       }
//     }
//     return true;
//   };

//   const handleSubmit = async () => {
//     if ( validateProperties ) {
//       await onSubmit( values, currentExpenses, extraCosts );
//       toggleForm();
//     }
//   };

//   return (
//     <Row className="mt-3 mb-3 mx--1">
//       <Col className="px-1">
//         <div className="text-bold text-small"><Intl id="name" /></div>
//         <input
//           type="text"
//           className="form-control-sm form-control"
//           value={values.name}
//           onChange={( e ) => {
//             const v = e.target.value;
//             setProperty( v, 'name' );
//           }}
//           required
//         />
//       </Col>
//       <Col className="px-1">
//         <div className="text-bold text-small"><Intl id="description" /></div>
//         <input
//           type="text"
//           className="form-control-sm form-control"
//           value={values.description}
//           onChange={( e ) => {
//             const v = e.target.value;
//             setProperty( v, 'description' );
//           }}
//           required
//         />
//       </Col>
//       <Col xs="auto" className="px-1">
//         <div style={{ width: 100 }}>
//           <div className="text-bold text-small"><Intl id="cost" /></div>
//           <input
//             type="number"
//             className="form-control-sm form-control"
//             value={values.cost}
//             required
//             onChange={( e ) => {
//               const v = parseInt( e.target.value, 10 );
//               setProperty( v, 'cost' );
//             }}
//           />
//         </div>
//       </Col>
//       <Col xs="2" className="px-1 pt-3">
//         <Button
//           color="primary"
//           className="text-uppercase text-xsm"
//           onClick={handleSubmit}
//         >
//           <Intl id="create" />
//         </Button>
//       </Col>
//       <Col xs={1} className="px-1 pt-3">
//         <div
//           className="p-1 text-danger d-inline-block cursor-pointer"
//           onClick={toggleForm}
//         >
//           <i className="fa fa-times" />
//         </div>
//       </Col>
//     </Row>
//   );
// };

export default ExpensesList;
