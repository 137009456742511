import React from 'react';
import GuestService from 'api/Guest';
import moment from 'moment';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise } from 'utils';
import _get from 'lodash/get';
import GuestModalFormView from '../../../../views/Booking/Edit/Overview/GuestModalForm';

const GuestModalForm = ( {
  openModalGuest,
  onCloseModalGuest,
  bookedAccommodation,
  onReloadData,
  toggleLoading,
  toggleErrorAlert,
  toggleInfoAlert,
  selectedGuest,
  isOwner,
} ) => {
  const idGuest = _get( selectedGuest, 'id' );
  const onSubmitGuest = async ( values ) => {
    const {
      selfie, reverseId, frontalId, signature,
      birthdate,
      documentNumber,
      documentType,
      expeditionDate,
      secondLastname,
      firstLastname,
      name,
      nationality,
      sex,
      locality,
      address,
      postalCode,
      phone,
      relationshipWithOwner,
      supportDocumentNumber,
    } = values;

    const images = [
      {
        type: 'selfie',
        image: _get( selfie, 'file' ),
      },
      {
        type: 'reverseId',
        image: _get( reverseId, 'file' ),
      },
      {
        type: 'frontalId',
        image: _get( frontalId, 'file' ),
      },
      {
        type: 'signature',
        image: _get( signature, 'file' ),
      },
    ].filter( ( { image } ) => image );

    toggleLoading( true );

    const dataToSend = {
      bookedAccommodation,
      documentNumber,
      documentType,
      secondLastname,
      firstLastname,
      name,
      nationality,
      country: nationality,
      locality,
      address,
      postalCode,
      phone,
      relationshipWithOwner,
      supportDocumentNumber,
      sex,
      birthdate: moment( birthdate ).format( 'YYYY-MM-DD' ),
      expeditionDate: moment( expeditionDate ).format( 'YYYY-MM-DD' ),
      travelReportStatus: 'PENDING_VALIDATION',
    };

    const [errors, response, guestData] = await handlePromise(
      idGuest ? GuestService.updateGuest( idGuest, dataToSend )
        : GuestService.saveGuest( dataToSend ),
    );

    if ( !response.ok ) {
      toggleLoading( false );
      onReloadData();
      toggleErrorAlert( errors || 'apiError' );
      return errors || 'apiError';
    }

    const imagesResponses = await Promise.all(
      images.map( ( img ) => handlePromise( GuestService.updateGuestImage(
        idGuest || guestData.id, img,
      ) ) ),
    );

    imagesResponses.forEach( ( rps ) => {
      const [errorImg, responseImg] = rps;
      if ( !responseImg.ok ) {
        toggleLoading( false );
        onReloadData();
        toggleErrorAlert( errorImg || 'apiError' );
        return errorImg || 'apiError';
      }
    } );
    onReloadData();
    onCloseModalGuest();
    toggleLoading( false );
    return toggleInfoAlert( 'dataSaved' );
  };

  return (
    <GuestModalFormView
      modalOpened={openModalGuest}
      handleClose={onCloseModalGuest}
      onSubmit={onSubmitGuest}
      selectedGuest={selectedGuest}
      isOwner={isOwner}
    />
  );
};

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};
export default connect( null, mapDispatchToProps )( GuestModalForm );
