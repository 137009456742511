import React from 'react';
import {
  Row, Col, Container,
} from 'reactstrap';

import {
  AsyncTable, AddBtn, SimpleHeader, TableGenericFilter, TableEditBtn,
  TableActionsBtns,
} from 'components';

export default React.memo( ( {
  data, isLoading, defaultFilters, onFetchData, onOpenEditModal, onDelete,
} ) => (
  <>
    <SimpleHeader title="extraExpensesTemplate" />
    <Container fluid>
      <AsyncTable
        id="extraExpensesTemplate"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        rightContent={<AddBtn title="newExtraExpenseTemplate" onClick={onOpenEditModal} />}
        onFetchData={onFetchData}
        customFilters={( { filters, setFilter } ) => (
          <Row>
            <Col md={8} lg={4}>
              <TableGenericFilter
                filters={filters}
                setFilter={setFilter}
              />
            </Col>
          </Row>
        )}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={cell.value}
                onClick={() => onOpenEditModal( { id: cell.row.original.id } )}
              />
            ),
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'cost',
            accessor: 'cost',
          },
          {
            accessor: 'id',
            disableSorting: true,
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => (
              <TableActionsBtns
                actions={[
                  {
                    isDelete: true,
                    onClick: ( cb ) => onDelete( cell.value, cb ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Container>
  </>
) );
