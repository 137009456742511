export default [
  {
    id: 'esPoliciaNacional',
    name: 'PoliciaNacional',
  },
  {
    id: 'esGuardiaCivil',
    name: 'GuardiaCivil',
  },
  {
    id: 'esMozosEscuadra',
    name: 'MozosEscuadra',
  },
  {
    id: 'esErtzaintza',
    name: 'Ertzainta',
  },
  {
    id: 'esMinisterio',
    name: 'Ministerio',
  },
];
