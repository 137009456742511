import _ from 'lodash';
import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import AccommodationOverview from 'views/DashboardPMS/AccommodationOverview';
import BookingService from 'api/Booking';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import IncidenceActions from 'store/reducers/Incidence';
import { handlePromise } from 'utils';
import EditTask from 'containers/Task/Edit';
import AccommodationService from 'api/Accommodation';
import moment from 'moment';
import TaskService from 'api/Task';

const DashboardPMS = ( {
  toggleErrorAlert, openModal, onClose, DMAccomodationId, toggleLoading: dispatchToggleLoading,
  currentDate, userBookingReference,
} ) => {
  const { getTasks } = TaskService;
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [taskPreloadedData, setTaskPreloadedData] = useState( {} );

  const [loading, setLoading] = useState( );
  const [data, setData] = useState( {} );

  const toggleLoading = useCallback(
    ( value ) => {
      dispatchToggleLoading( value );
      setLoading( value );
    }, [dispatchToggleLoading],
  );

  const [openEditTaskModal, setOpenEditTaskModal] = useState( false );
  const toggleEditTaskModal = () => setOpenEditTaskModal( ( state ) => !state );
  const selectedTaskId = useRef();

  const loadData = useCallback( async () => {
    setData( {} );
    toggleLoading( true );
    const [errors, response, responseData] = await handlePromise(
      AccommodationService.getGenericAccommodation( DMAccomodationId ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errors );
    }
    const date = currentDate.format( 'YYYY-MM-DD' );
    const tillStart = moment( currentDate ).add( '-1', 'day' ).format( 'YYYY-MM-DD' );

    const [errorsCheckin, responseCheckin, responseDataCheckin] = await handlePromise(
      BookingService.getBookings( {
        accommodation: DMAccomodationId,
        fromEnd: date,
        fromStart: date,
      } ),
    );
    if ( !responseCheckin.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errorsCheckin );
    }
    const checkInData = _get( responseDataCheckin, 'data[0]' );
    const [errorsCheckOut, responseCheckOut, responseDataCheckOut] = await handlePromise(
      BookingService.getBookings( {
        accommodation: DMAccomodationId,
        tillStart,
        tillEnd: date,
      } ),
    );
    if ( !responseCheckOut.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errorsCheckOut );
    }
    const checkOutData = _get( responseDataCheckOut, 'data[0]' );

    const [errorsTasks, responseTasks, responseDataTasks] = await handlePromise(
      getTasks( {
        accommodation: DMAccomodationId,
        from: date,
        till: date,
      } ),
    );
    if ( !responseTasks.ok ) {
      toggleLoading( false );
      return toggleErrorAlert( errorsTasks );
    }
    setData( {
      ...responseData,
      checkOutData,
      checkInData,
      tasks: _get( responseDataTasks, 'data', [] ),
    } );
    toggleLoading( false );
  }, [DMAccomodationId, toggleErrorAlert, toggleLoading, currentDate, getTasks] );

  useEffect( () => {
    if ( openModal && DMAccomodationId ) loadData();
  }, [openModal, DMAccomodationId, dataSubmitted, loadData] );

  const handleOnOpenTaskEditModal = ( { id } ) => {
    if ( !id ) {
      setTaskPreloadedData( {
        accommodation: {
          value: data.id,
          label: data.name,
        },
      } );
    } else {
      setTaskPreloadedData( {} );
    }
    selectedTaskId.current = id;
    toggleEditTaskModal();
  };

  const reloadData = () => setDataSubmitted( new Date().valueOf() );

  return (
    <>
      <AccommodationOverview
        open={openModal}
        onClose={onClose}
        data={data}
        isLoading={loading}
        userBookingReference={userBookingReference}
        onOpenTaskEditModal={handleOnOpenTaskEditModal}
      />
      <EditTask
        taskId={selectedTaskId.current}
        preloadedData={taskPreloadedData}
        modalOpened={openEditTaskModal}
        onCloseModal={toggleEditTaskModal}
        zIndex={9999}
        onReload={reloadData}
        onUpdateFieldTask={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user, incidence } ) => ( {
  propertyManager: _get( user, 'user.propertyManager.id', {} ),
  incidenceReloadedAt: incidence.reloadedAt,
  userBookingReference: _.get( user, 'settings.bookingReference' ),
} );

const mapDispatchToProps = {
  toggleIncidentModal: IncidenceActions.toggleIncidenceEditModal,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  // toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( DashboardPMS );
