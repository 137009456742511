import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import LoginView from 'views/Login';
import AuthService from 'api/Auth';
import EmployeeService from 'api/Employee';
import CurrencyService from 'api/Settings/Currency';
import PluginService from 'api/Plugin';
import AppVersionService from 'api/AppVersion';
import PropertyManagerService from 'api/PropertyManager';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import { handlePromise, storage } from 'utils';
import { locales, noCustomerAppPM } from 'config/Constants';
import utils from './utils';

const Login = ( {
  history, toggleLoading, loginUser, toggleErrorAlert, switchLanguage, updateAppVersion,
} ) => {
  const [workspaceVisible, setWorkspaceVisible] = useState( false );
  const [dataLoading, setDataLoading] = useState( true );
  const [uiVisible, setUiVisible] = useState( false );
  const pmData = useRef( {} );

  useEffect( () => {
    const getData = async () => {
      const pmName = window.location.host.split( '.' )[0].split( ':' )[0];

      if ( pmName === 'manager' || pmName === 'manager-dev' || pmName === 'manager-staging' || pmName === 'localhost' ) {
        setWorkspaceVisible( true );
        setUiVisible( true );
        setDataLoading( false );
      } else {
        setDataLoading( true );
        const [errors, response, responseData] = await handlePromise(
          PropertyManagerService.getPropertyManagerByWorkspace( pmName ),
        );

        setDataLoading( false );
        setUiVisible( true );
        if ( !response.ok ) {
          setWorkspaceVisible( true );
          return toggleErrorAlert( errors );
        }
        pmData.current = { ...responseData, name: pmName };
      }
    };
    getData();
  }, [toggleErrorAlert] );

  const submitWorkspaceForm = useCallback( async ( formData ) => {
    toggleLoading( true );
    const [errors, response, responseData] = await handlePromise(
      PropertyManagerService.getPropertyManagerByWorkspace( formData.workspace ),
    );
    toggleLoading( false );
    if ( !response.ok ) {
      return { workspace: errors };
    }

    pmData.current = { ...responseData, name: formData.workspace };
    setWorkspaceVisible( false );
  }, [toggleLoading] );

  const submitForm = useCallback( async ( formData ) => {
    toggleLoading( true );

    const error = ( errorMsg ) => {
      toggleLoading( false );
      return errorMsg || 'apiError';
    };

    const state = new Date().valueOf().toString();
    const [errors, response, data] = await handlePromise( AuthService.requestAuthCode( {
      username: formData.email,
      password: formData.password,
      state,
      // propertyManager: pmData.current.id,
    } ) );
    if ( !response.ok || data.state !== state ) {
      toggleLoading( false );
      return errors || 'apiError';
    }

    const [loginErrors, loginResponse, loginData] = await handlePromise(
      AuthService.loginUser( { code: data.code } ),
    );
    if ( !loginResponse.ok ) return error( loginErrors );
    storage.set( 'nomads:token', loginData.accessToken );

    // eslint-disable-next-line no-unused-vars
    const [userErrors, userResponse, userData] = await handlePromise(
      EmployeeService.getEmployee( loginData.user.id ),
    );
    if ( !userResponse.ok ) {
      storage.clear( 'nomads:token' );
      return error( 'apiError' );
    }

    // eslint-disable-next-line no-unused-vars
    const [versionErrors, versionResponse, versionData] = await handlePromise(
      AppVersionService.getAppVersions(),
    );
    if ( !versionResponse.ok ) {
      storage.clear( 'nomads:token' );
      return error( 'apiError' );
    }

    _.set( loginData, 'user.user.firstName', userData.firstName );
    _.set( loginData, 'user.user.lastName', userData.lastName );
    _.set( loginData, 'user.user.profilePicture', userData.profilePicture );
    _.set( loginData, 'user.user.roles', userData.roles );
    _.set( loginData, 'user.roles', userData.roles );

    let locale = _.find( locales, { locale: _.get( userData, 'settings.locale', 'en' ) } );
    if ( locale.locale !== 'es' && locale.locale !== 'en' && locale.locale !== 'it' && locale.locale !== 'fr' ) {
      locale = {
        locale: 'en',
        name: 'English',
      };
    }

    if ( _.get( loginData, 'user.propertyManager.id' ) ) {
      // eslint-disable-next-line no-unused-vars
      const [currencyErrors, currencyResponse, currencyData] = await handlePromise(
        CurrencyService.getCurrency( { propertyManager: loginData.user.propertyManager.id } ),
      );
      if ( !currencyResponse.ok ) {
        storage.clear( 'nomads:token' );
        return error( 'apiError' );
      }
      const [, propertyManagerResponse, propertyManagerData] = await handlePromise(
        PropertyManagerService.getPropertyManager( loginData.user.propertyManager.id ),
      );
      if ( !propertyManagerResponse.ok ) {
        storage.clear( 'nomads:token' );
        return error( 'apiError' );
      }
      const [, , pluginData] = await handlePromise(
        PluginService.getPlugins( {
          page: 1,
          elementsPerPage: 20,
          installed: 'true',
          private: 'false',
        } ),
      );

      const hasRU = _.find( pluginData.data, ( item ) => item.key === 'rentals_united' );

      _.set( loginData, 'settings.bookingReference', propertyManagerData.bookingReference );
      _.set( loginData, 'settings.currency', currencyData.currency );
      _.set( loginData, 'settings.ruInstalled', Boolean( hasRU ) );
      // ToDo validation if api returns contentLocale
      storage.set( 'nomads:contentLocale', locale.locale );
      _.set( loginData, 'settings.contentLocale', locale.locale );
      _.set( loginData, 'settings.locales', ['en', 'es', 'fr', 'de', 'it', 'pt'] );

      _.set( loginData, 'settings.haveCustomerApp', !noCustomerAppPM.includes( loginData.user.propertyManager.id ) );
    }

    loginUser( loginData );
    updateAppVersion( {
      appVersion: utils.getVersionNumber( versionData.Manager || 'v0.1' ),
    } );
    if ( locale ) switchLanguage( locale );
    toggleLoading( false );
    return history.push( '/' );
  }, [history, loginUser, switchLanguage, toggleLoading, updateAppVersion] );

  return (
    <LoginView
      pmData={pmData.current}
      uiVisible={uiVisible}
      isLoading={dataLoading}
      workspaceVisible={workspaceVisible}
      onSubmit={submitForm}
      onSubmitWorkspace={submitWorkspaceForm}
    />
  );
};

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  switchLanguage: SettingsActions.switchLanguage,
  updateAppVersion: SettingsActions.updateAppVersion,
} );

export default connect( null, mapDispatchToProps )( Login );
