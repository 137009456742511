import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  FormActionsBtns,
  CanAccess,
  Datepicker,
  EditCard,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo( ( { data, paths, onSubmit } ) => (
  <EditCard cardClass="card-sec-bottom">
    <Form
      initialValues={data}
      onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
      showErrorsInFooter
      body={() => (
        <>
          <Row>
            <Col md={6}>
              <Datepicker
                field="checkInFrom"
                label="checkInOut:checkInFrom"
                validate={required}
                dateFormat={false}
                timeFormat="HH:mm"
                utc
              />
            </Col>
            <Col md={6}>
              <Datepicker
                field="checkInTo"
                label="checkInOut:checkInTo"
                validate={required}
                dateFormat={false}
                timeFormat="HH:mm"
                utc
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Datepicker
                field="checkOutUntil"
                label="checkInOut:checkOutUntil"
                validate={required}
                dateFormat={false}
                timeFormat="HH:mm"
                utc
              />
            </Col>
            <Col md={6}>
              <TextField
                field="daysBeforeArrival"
                label="checkInOut:daysBeforeArrival"
                type="number"
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                field="place"
                label="checkInOut:place"
                type="textarea"
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
      footer={(
        <CanAccess I="edit" a="accommodation">
          <FormActionsBtns cancelTo={paths.accommodation} />
        </CanAccess>
            )}
    />
  </EditCard>

) );
