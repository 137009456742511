import React from 'react';

import { DetailCard, Intl } from 'components';
import {
  accommodationFields,
  bookingFields,
  customerFields,
  paymentsFields,
  propertyFields,
  roomTypeFields,
} from './variablesList';
import AccordionForList from './components/AccordionForList';

export default () => (
  <DetailCard>
    <h3>Variables</h3>
    <small>
      <Intl id="varTexts" />
    </small>
    <AccordionForList name="customer" fields={customerFields} />
    <AccordionForList name="booking" fields={bookingFields} />
    <AccordionForList name="accommodation" fields={accommodationFields} />
    <AccordionForList name="roomType" fields={roomTypeFields} />
    <AccordionForList name="property" fields={propertyFields} />
    <AccordionForList name="payments" fields={paymentsFields} />
  </DetailCard>
);
