import React from 'react';
import _ from 'lodash';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';

import {
  Form, CustomField, FormActionsBtns, EditCard, CanAccess, Select, AsyncTable, Intl,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo( ( {
  data, extraData, paths, onSubmit, configuredPlugins, onFetchData, dataLoading,
  handlePluginChange,
} ) => (
  <EditCard cardClass="card-sec-bottom">
    <Card data-cy="table-container" className="table-cell-padding">
      <CardBody>
        <h4 className="m-0 pb-4">
          <Intl id="install" />
        </h4>
        <Form
          initialValues={data.entity}
          onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
          showErrorsInFooter
          body={() => (
            <Row>
              <Col md={4}>
                <Select
                  required
                  label="Plugins"
                  field="plugin"
                  className="form-control"
                  placeholder="smartLocker:Select"
                  options={extraData.plugins}
                  onChange={handlePluginChange}
                />
              </Col>
              {_.map( data.customFields, ( item, index ) => (
                <Col md={4} key={index}>
                  <CustomField field={item} />
                </Col>
              ) )}
            </Row>
          )}
          footer={(
            <CanAccess I="edit" a="accommodation">
              {data.customFields && data.customFields.length
                ? <FormActionsBtns cancelTo={paths.accommodation} /> : <span />}
            </CanAccess>
          )}
        />
      </CardBody>
    </Card>
    <AsyncTable
      titleContent={(
        <h4 className="m-0 pb-2">
          <Intl id="plugin:configured" />
        </h4>
      )}
      id="plugins"
      data={configuredPlugins.data}
      total={configuredPlugins.elementsTotal}
      isLoading={dataLoading}
      disableSorting
      onFetchData={onFetchData}
      useElementsPerPage={false}
      columns={[
        {
          Header: 'Locker id',
          accessor: 'locker_id',
        },
        {
          Header: 'provider',
          accessor: 'provider',
        },
      ]}
    />
  </EditCard>
) );
