import Wifi from 'containers/Property/RoomType/Accommodation/Edit/Wifi';
import CommunityRule from 'containers/Property/RoomType/Accommodation/Edit/CommunityRule/Dashboard';
import TravelReport from 'containers/Property/RoomType/Accommodation/Edit/TravelReport';
import Smartlockers from 'containers/Property/RoomType/Accommodation/Edit/Smartlockers';

export default [
  {
    name: 'communityRules',
    path: '/community-rules',
    component: CommunityRule,
  },
  {
    name: 'smartLocker',
    path: '/smart-locker',
    component: Smartlockers,
    extraData: {
      filter: ( { category } ) => category === 'smartlockers',
      title: 'smartLocker',
    },
  },
  {
    name: 'Wifi',
    path: '/wifi',
    component: Wifi,
  },
  {
    name: 'travelReport',
    path: '/travelReport',
    component: TravelReport,
  },
];
