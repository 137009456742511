import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  FormActionsBtns,
  EditCard,
  CanAccess,
  Switch,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    paths,
    onSubmit,
  } ) => (
    <EditCard
      cardClass="card-sec-bottom"
    >
      <Form
        initialValues={data}
        onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
        showErrorsInFooter
        body={() => (
          <>
            <Row>
              <Col md={6}>
                <Switch field="enabled" label="enabled" />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField field="name" label="name" validate={required} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField field="password" label="password" />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField
                  field="description"
                  label="description"
                  type="textarea"
                />
              </Col>
            </Row>
          </>
        )}
        footer={(
          <CanAccess I="edit" a="accommodation">
            <FormActionsBtns cancelTo={paths.accommodation} />
          </CanAccess>
        )}
      />
    </EditCard>
  ),
);
