export default [
  {
    name: 'ruInfo',
    path: '/info',
  },
  {
    name: 'accommodation.landlord',
    path: '/accommodation-landlord',
  },
  {
    name: 'arrivalInstructions',
    path: '/arrival-instructions',
  },
  {
    name: 'checkInOut',
    path: '/check-in-out',
  },
  {
    name: 'ruFees',
    path: '/fees',
  },
];
