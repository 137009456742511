import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  FormActionsBtns,
  CanAccess,
  Phone,
  EditCard,
} from 'components';
import { composeValidators, email, required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import _get from 'lodash/get';

export default React.memo( ( { data, paths, onSubmit } ) => (
  <EditCard cardClass="card-sec-bottom">
    <Form
      initialValues={_get( data, 'landlord', {} )}
      onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
      showErrorsInFooter
      body={() => (
        <>
          <Row>
            <Col md={6}>
              <TextField field="name" label="name" validate={required} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                field="lastname"
                label="lastname"
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                field="email"
                validate={composeValidators( required, email )}
                label="email"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Phone field="phoneNumber" label="phone" validate={required} />
            </Col>
          </Row>
        </>
      )}
      footer={(
        <CanAccess I="edit" a="accommodation">
          <FormActionsBtns cancelTo={paths.accommodation} />
        </CanAccess>
        )}
    />
  </EditCard>
) );
