import {
  useState, useCallback,
  // useMemo,
} from 'react';
import { useFetch, useFilters } from 'hooks';
import MessageService from 'api/Hub/Message';
import { handlePromise } from 'utils';
import { MESSAGES_PER_PAGE } from '../constants';

const useMessages = (
  participantId,
  selectedChannelId,
  selectedSourceId,
  toggleErrorAlert,
) => {
  const [updateMessageView, setUpdateMessageView] = useState( 1 );

  const onUpdateMessageView = useCallback( () => {
    setUpdateMessageView( ( v ) => v + 1 );
  }, [] );

  //
  const [totalPag, setTotalPag] = useState( 1 );

  //  MESSAGE LIST
  const [messages, setMessages] = useState( {
    totalPages: 0,
    pagesLoaded: {},
    lastPageLoaded: 0,
    data: [],
  } );
  const [filterMessages, setFilterMessages] = useFilters( {
    page: 1,
    limit: MESSAGES_PER_PAGE,
    'orderBy[createdAt]': 'desc',
  } );
  const [reloadMessagesFlag, setReloadMessagesFlag] = useState( 0 );
  const [, loadingMessages] = useFetch( {
    initialState: { data: [], total: 0, elementsPerPage: MESSAGES_PER_PAGE },
    promise: () => {
      const filterChannelSelected = selectedChannelId ? { 'filters[channelId]': selectedChannelId ?? '' } : {};
      const filterSourceSelected = selectedSourceId ? { 'filters[sourceId]': selectedSourceId ?? '' } : {};
      return MessageService.getMessages( {
        ...filterMessages,
        ...filterChannelSelected,
        ...filterSourceSelected,
        page: Math.min( ( filterMessages?.page || 1 ), totalPag ),
      } );
    },
    toggleErrorAlert,
    conditional: !!selectedChannelId,
    deps: [selectedChannelId, selectedSourceId, reloadMessagesFlag, filterMessages],
    reInit: true,
    callback: ( sc, ss, r, { page }, newMessageListData ) => {
      setMessages( ( oldMessageListData ) => {
        const totalPages = Math.ceil( newMessageListData.total / MESSAGES_PER_PAGE );
        setTotalPag( totalPages );

        const lastPageLoaded = page;

        const dataRaw = [...oldMessageListData.data, ...newMessageListData.data]
          .reduce( ( obj, item ) => {
            if ( !obj[item.id] ) {
              // eslint-disable-next-line no-param-reassign
              obj[item.id] = item;
            }
            return obj;
          }, {} );

        const data = Object.values( dataRaw ).sort( ( a, b ) => a.createdAt - b.createdAt );
        onUpdateMessageView();
        return {
          totalPages,
          pagesLoaded: {},
          lastPageLoaded,
          data,
        };
      } );
    },
  } );
  //  MESSAGE SENDER
  const [loadingMessage, setLoadingMessage] = useState( false );
  const submitMessage = useCallback(
    async ( content, file ) => {
      setLoadingMessage( true );
      const sourceIdContent = selectedSourceId ? { sourceId: selectedSourceId } : {};

      const [error, response] = await handlePromise( MessageService.createMessage( {
        participantId,
        channelId: selectedChannelId,
        content,
        ...sourceIdContent,
      }, file ) );
      if ( !response.ok ) {
        toggleErrorAlert( error );
      } else {
        setReloadMessagesFlag( new Date().valueOf() );
      }
      setLoadingMessage( false );
    },
    [toggleErrorAlert, participantId, selectedChannelId, selectedSourceId],
  );

  const addNewMessages = useCallback( ( newMessagesData ) => {
    setMessages( ( oldMessageList ) => {
      const data = [...oldMessageList.data, ...newMessagesData]
        .sort( ( a, b ) => a.createdAt - b.createdAt );

      return {
        ...oldMessageList,
        data,
      };
    } );
    onUpdateMessageView();
  }, [onUpdateMessageView] );

  // const enabledSubmitMessage = useMemo( () => {
  //   if ( !selectedChannel ) {
  //     return false;
  //   }
  //   return selectedChannel.participants
  //     .filter( ( item ) => item.id === participantId ).length > 0;
  // }, [selectedChannel, participantId] );

  const enabledSubmitMessage = true;

  const resetMessages = useCallback(
    ( ) => {
      setMessages( {
        totalPages: 0,
        pagesLoaded: {},
        lastPageLoaded: 0,
        data: [],
      } );
      setFilterMessages( { page: 1 } );
    },
    [setFilterMessages],
  );

  return [
    messages,
    resetMessages,
    loadingMessages,
    filterMessages,
    setFilterMessages,
    enabledSubmitMessage,
    submitMessage,
    loadingMessage,
    addNewMessages,
    updateMessageView,
  ];
};

export default useMessages;
