import React, { useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import classnames from 'classnames';

import { Intl } from 'components';
import BadgeClip from './BadgeClip';

export default ( { name, fields } ) => {
  const [showVariables, setShowVariables] = useState( false );
  const toggleShowVariables = () => setShowVariables( !showVariables );
  return (
    <div className="accordion">
      <Row
        role="tab"
        onClick={toggleShowVariables}
        aria-expanded={showVariables}
      >
        <Col className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="font-weight-bold text-primary text-uppercase">
              <Intl id={name} />
            </span>
          </div>
          <i
            className={classnames( 'fa p-2', {
              'fa-chevron-down': showVariables,
              'fa-chevron-up': !showVariables,
            } )}
          />
        </Col>
      </Row>

      <Collapse role="tabpanel" isOpen={showVariables}>
        <ul className="badge-vars">
          {fields.map( ( field ) => (
            <BadgeClip text={`{{${field}}}`} />
          ) )}
        </ul>
      </Collapse>
    </div>
  );
};
