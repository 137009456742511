import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import NewFunctionalityComponent from 'containers/NewFuntionality/Component';
import RatesDashboardView from 'views/Property/RoomType/Accommodation/Edit/Rates/Dashboard';

const RatesDashboard = ( {
  entityId,
  data,
  parentIds,
  onReloadData,
  ruInstalled,
} ) => {
  if ( !ruInstalled ) {
    return <NewFunctionalityComponent />;
  }

  return (
    <RatesDashboardView
      name={data.entity ? data.entity.name : ''}
      data={data}
      entityId={entityId}
      parentIds={parentIds}
      onReloadData={onReloadData}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  ruInstalled: _.get( user, 'settings.ruInstalled', false ),
} );
const mapDispatchToProps = {};

export default connect( mapStateToProps, mapDispatchToProps )( RatesDashboard );
