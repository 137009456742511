import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  DaterangePickerBtn,
  Intl,
  TableCellList,
  Switch,
  Button,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    data,
    isLoading,
    defaultFilters,
    onFetchData,
    selectedRows,
    onToggleRowChecked,
    onToggleAllRowsChecked,
    onApplyToChange,
  } ) => (
    <>
      <h3>
        <Intl id="taskExpenses" />
      </h3>
      <AsyncTable
        id="bookings"
        data={data.data}
        total={data.elementsTotal}
        isLoading={isLoading}
        disableSorting
        defaultFilters={defaultFilters}
        onFetchData={onFetchData}
        customFilters={( { filters, setMultipleFilters } ) => (
          <Row>
            <Col xs="auto">
              <div style={{ width: 360 }}>
                <DaterangePickerBtn
                  position="left"
                  label="tasksBetween"
                  initialValue={moment.range(
                    filters.from || moment().subtract( 1, 'months' ),
                    filters.till || moment(),
                  )}
                  key={filters.from + filters.till}
                  input
                  onChange={( values ) => {
                    setMultipleFilters( {
                      from: moment( values.start ).format( 'YYYY-MM-DD' ),
                      till: moment( values.end ).format( 'YYYY-MM-DD' ),
                    } );
                  }}
                />
              </div>
            </Col>
            {data && data.totalExpenses ? (
              <Col xs="auto" className="pt-3">
                <h4 className="pt-4">
                  <span className="text-normal">
                    <Intl id="total" />
                    :
                  </span>
                  {` ${data.totalExpenses}`}
                  &euro;
                </h4>
              </Col>
            ) : null}
          </Row>
        )}
        columns={[
          {
            Header: (
              <div className="text-left">
                <span
                  className="d-inline-block"
                  style={{ position: 'relative', top: 2 }}
                >
                  <Switch
                    name="header"
                    formGroupClass="m-0 pl-3 d-inline-block"
                    disabled={!canModify( 'accommodation' )}
                    checked={
                      data.data
                      && data.data.length
                      && data.data.length
                        === Object.entries( selectedRows ).filter(
                          ( [, value] ) => value,
                        ).length
                    }
                    hideSideLabels
                    onChange={onToggleAllRowsChecked}
                  />
                </span>
                <span className="align-top ml-1 d-inline-block">
                  <Intl id="invoiced" />
                </span>
              </div>
            ),
            id: 'check',
            accessor: 'id',
            maxWidth: 155,
            Cell: ( { cell } ) => {
              const title = `${cell.row.original.id.slice(
                cell.row.original.id.length - 6,
                cell.row.original.id.length,
              )}`;
              return (
                <div className="text-left">
                  <Switch
                    name={cell.value}
                    disabled={cell.row.original.invoiced || !canModify( 'accommodation' )}
                    formGroupClass="m-0 pl-3 d-inline-block"
                    checked={selectedRows[cell.value]}
                    hideSideLabels
                    onChange={( e ) => {
                      onToggleRowChecked( cell.value, e.target.checked );
                    }}
                  />
                  <b className="text-uppercase pl-2">{title}</b>
                </div>
              );
            },
          },
          {
            Header: 'category',
            accessor: 'taskType.name',
          },
          {
            Header: 'date',
            accessor: 'date',
            Cell: ( { cell } ) => ( cell.value
              ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' )
              : '' ),
          },
          {
            Header: 'applyToCost',
            accessor: 'id',
            maxWidth: 200,
            Cell: ( { cell } ) => (
              <div className="text-center">
                {!cell.row.original.extraCost ? <Intl id="nocosts" />
                  : (
                    <Button
                      size="sm"
                      color="primary"
                      outline
                      type="button"
                      className="px-4 py-2"
                      onClick={() => onApplyToChange( cell.value, cell.row.original )}
                      title="edit"
                    />
                  )}
              </div>
            ),
          },
          {
            Header: 'cost',
            accessor: 'taskType',
            Cell: ( { cell } ) => <span>{cell.value.cost || '-'}</span>,
          },
          {
            Header: 'extraCost',
            accessor: 'extraCost',
            Cell: ( { cell } ) => (
              <TableCellList
                list={cell.value}
                limit={6}
                render={( ec, k ) => (
                  <div className="small" key={k}>
                    {`${ec.description} - ${ec.totalCost} `}
                    &euro;
                  </div>
                )}
              />
            ),
          },
        ]}
      />
    </>
  ),
);
