import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  Form,
  TextField,
  Switch,
  FormActionsBtns,
  EditCard,
  Select,
  CanAccess,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import Provinces from 'config/Constants/Provinces';

export default React.memo(
  ( {
    data,
    entityId,
    options,
    isZonesLoading,
    parentPath,
    onSubmit,
    onChangeCity,
  } ) => (
    <EditCard
      title={entityId ? 'editBuilding' : 'newBuilding'}
      cardClass="card-sec-bottom"
    >
      <Form
        initialValues={data && data.entity}
        onSubmit={canModify( 'property' ) ? onSubmit : () => {}}
        showErrorsInFooter
        body={( form ) => (
          <>
            <Row>
              <Col md={5}>
                <TextField field="name" label="name" validate={required} />
              </Col>
              <Col md={2}>
                <TextField
                  field="floors"
                  label="floors"
                  type="number"
                  validate={required}
                />
              </Col>
              <Col md={5}>
                <Switch field="haveLift" label="haveLift" />
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <TextField field="municipality" label="municipality" />
              </Col>
              <Col md={2}>
                <Select field="province" label="province" options={Provinces} />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <TextField
                  field="address"
                  label="address"
                  validate={required}
                />
              </Col>
              <Col md={1}>
                <TextField field="addressNumber" label="number" />
              </Col>
              <Col md={2}>
                <TextField field="postalCode" label="postalCode" />
              </Col>
              <Col md={2}>
                <Select
                  field="city"
                  label="city"
                  options={options.cities}
                  validate={required}
                  onChange={( cityId ) => {
                    form.change( 'propertyManagerCityZoneId', null );
                    if ( form.getFieldState( 'city' ) ) {
                      onChangeCity( cityId );
                    }
                  }}
                />
              </Col>
              <Col md={2}>
                <Select
                  field="propertyManagerCityZoneId"
                  label="zone"
                  options={options.zones}
                  validate={required}
                  isLoading={isZonesLoading}
                />
              </Col>
              <Col md={3}>
                <TextField field="latitude" label="latitude" />
              </Col>
              <Col md={3}>
                <TextField field="longitude" label="longitude" />
              </Col>
            </Row>
          </>
        )}
        footer={(
          <CanAccess I="edit" a="property">
            <FormActionsBtns cancelTo={parentPath} />
          </CanAccess>
        )}
      />
    </EditCard>
  ),
);
