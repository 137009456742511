export default [
  'checkin_day',
  'checkout_day',
  'checkin_time',
  'checkout_time',
  'booking_cost',
  'guests_number',
  'localizator',
  'booking_id',
];
