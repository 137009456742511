import React, { useState } from 'react';
import { canModify } from 'config/Ability';
import { EditCard, HorizontalMenu, Intl } from 'components';
import PropertyInfo from 'containers/Property/RoomType/Accommodation/Edit/RUInfo/Form';
import LandlordInfo from 'containers/Property/RoomType/Accommodation/Edit/Landlord/Form';
import ArrivalInfo from 'containers/Property/RoomType/Accommodation/Edit/ArrivalInstructions/Form';
import CheckInfo from 'containers/Property/RoomType/Accommodation/Edit/CheckInOut/Form';
import FeesInfo from 'containers/Property/RoomType/Accommodation/Edit/RUFees/Form';
import { Card, Col, Row } from 'reactstrap';

export default React.memo(
  ( {
    name,
    entityId,
    data,
    paths,
    parentIds,
    onReloadData,
    pathname,
    tabs,
  } ) => {
    const [activeTab, setactiveTab] = useState( 'ruInfo' );

    return (
      <EditCard
        headerContent={(
          <>
            <h3 className="mt-1 pb-0">
              <Intl
                id={
                  canModify( 'accommodation' )
                    ? 'editAccommodation'
                    : 'accommodation'
                }
              />
              {` ${name}`}
            </h3>
          </>
        )}
        cardClass="card-sec-bottom"
      >
        <Card className="card-sec-top border-bottom">
          <HorizontalMenu
            tabs={tabs}
            pathLayout={pathname}
            pathname={pathname}
            selected={activeTab}
            onChangeTab={setactiveTab}
          />
        </Card>
        <Row>
          <Col>
            {activeTab === 'ruInfo' ? (
              <PropertyInfo
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                name={name}
                onReloadData={onReloadData}
              />
            ) : null}
            {activeTab === 'accommodation.landlord' ? (
              <LandlordInfo
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                name={name}
                onReloadData={onReloadData}
              />
            ) : null}
            {activeTab === 'arrivalInstructions' ? (
              <ArrivalInfo
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                name={name}
                onReloadData={onReloadData}
              />
            ) : null}
            {activeTab === 'checkInOut' ? (
              <CheckInfo
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                name={name}
                onReloadData={onReloadData}
              />
            ) : null}
            {activeTab === 'ruFees' ? (
              <FeesInfo
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                name={name}
                onReloadData={onReloadData}
              />
            ) : null}
          </Col>
        </Row>
      </EditCard>
    );
  },
);
