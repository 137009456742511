import React from 'react';
import SettingsActions from 'store/reducers/Settings';
import { connect } from 'react-redux';
import SettingsAppView from 'views/Property/RoomType/Accommodation/Edit/SettingsApp';
import settingsAppRoutes from './settingsAppRoutes';

const SettingsApp = ( {
  data,
  entityId,
  paths,
  parentIds,
  onReloadData,
  pathLayout,
  pathname,
  extraData,
} ) => (
  <SettingsAppView
    name={data.entity ? data.entity.name : ''}
    data={data}
    tabs={settingsAppRoutes}
    entityId={entityId}
    paths={paths}
    parentIds={parentIds}
    pathname={pathname}
    extraData={extraData}
    pathLayout={pathLayout}
    onReloadData={onReloadData}
  />
);

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( SettingsApp );
