export default [
  'name',
  'lastname',
  'second_last_name',
  'email',
  'unique_code',
  'nationality',
  'birthdate',
  'sex',
  'address',
  'postal_code',
  'locality',
  'phone',
  'relationship_with_owner',
  'document_type',
  'document_number',
  'support_document_number',
  'expedition_date',
];
