import React, { useState } from 'react';
import {
  Container, Collapse, CardBody, Card, CardHeader, Row, Col,
} from 'reactstrap';
import {
  SimpleHeader, Intl, Select, AsyncSelect, Datepicker,
} from 'components';
import classnames from 'classnames';
import _get from 'lodash/get';
import moment from 'moment';
import { cleaningStatus } from 'config/Constants';
import _ from 'lodash';

const getAccommodationStatus = ( { empty, checkout, checkin } ) => {
  if ( empty ) return 'empty';
  if ( checkout && checkin ) return 'checkinCheckout';
  if ( checkout ) return 'checkout';
  if ( checkin ) return 'checkin';
  return 'customerInside';
};

const Statistic = ( { title, value } ) => (
  <Col>
    <div className="dashPMS-statistic">
      <span className="tit"><Intl id={title} /></span>
      <span className="num">
        {value}
      </span>
    </div>
  </Col>
);

const AccommodationCard = ( {
  onClickAccomodation, accommodationName, data,
  // statusCleaning,
  checkout, checkin, empty, tasksPending,
} ) => {
  const status = getAccommodationStatus( { empty, checkout, checkin } );
  // backgroundColor: COLORS_ACCOMMODATION_BY_STATUS[status],
  if ( data.hide ) {
    return null;
  }
  return (
    <Card
      onClick={onClickAccomodation}
      className={classnames( 'accommodation-card-pms', status )}
    >
      <CardBody className="p-3">
        <div className="accommodation-card-pms__title">
          {/* <span>
            <i className={statusCleaning === 'pending' ?
            'fas fa-broom text-danger' : 'fas fa-broom text-success'} />
          </span>
          {' '} */}
          {accommodationName}
          <span>
            <i className={_.get( data, 'cleaningStatus' ) === 'pending' ? 'fas fa-broom text-danger ml-1' : 'fas fa-broom text-success ml-1'} />
          </span>
        </div>
        <div>
          {tasksPending > 0 && (
            <div className="accommodation-card-pms__task-pending">
              <span style={{ marginRight: 6 }}>
                <i className="far fa-clock" />
              </span>
              {`${tasksPending} tasks`}
            </div>
          )}
        </div>
        {checkout && (
          <>
            {`Out: ${checkout}`}
            <br />
          </>
        ) }
        {checkin && (
          <>
            {`In: ${checkin}`}
            <br />
          </>
        )}
        {status === 'customerInside' && <>Customer Inside</>}
        {status === 'empty' && <>Empty</>}
      </CardBody>
    </Card>
  );
};

const RoomType = ( { name, data, onClickAccomodation } ) => (
  <div>
    <h3>{name}</h3>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {data.map(
        ( accommodation ) => (
          <AccommodationCard
            key={accommodation.accommodationId}
            backgroundColor="#dca495"
            data={accommodation}
            accommodationName={accommodation.accommodationName}
            statusCleaning={accommodation.cleaningStatus}
            checkout={accommodation.checkout}
            checkin={accommodation.checkin}
            empty={accommodation.empty}
            tasksPending={accommodation.tasksPending}
            onClickAccomodation={() => onClickAccomodation( accommodation.accommodationId )}
          />
        ),
      )}
    </div>
  </div>
);

const PM = ( {
  name, data, onClickAccomodation, checkout, checkin,
  tasksPending, tasksClosed, empty, checkInOut,
} ) => {
  const [isOpen, setIsOpen] = useState( true );
  const toggle = () => setIsOpen( !isOpen );

  const titleItems = [
    {
      label: '',
      text: name,
    },
    {
      label: 'In + out:',
      text: checkInOut,
    },
    {
      label: 'In:',
      text: checkin,
    },
    {
      label: 'Out:',
      text: checkout,
    },
    {
      label: 'Empty:',
      text: empty,
    },
    {
      label: 'Tasks pending:',
      text: tasksPending,
    },
    {
      label: 'Tasks closed:',
      text: tasksClosed,
    },
  ];

  return (
    <Card>
      <CardHeader style={{ paddingTop: 8, paddingBottom: 8 }}>
        <div onClick={toggle}>
          <div className="pm-title">
            {titleItems.map( ( item, index ) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="pm-title_box" key={index}>
                {`${item.label} `}
                <b>{item.text}</b>
              </div>
            ) )}
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          {data.map( ( roomType ) => {
            const roomTypeData = roomType[0];
            return (
              <RoomType
                key={roomTypeData.roomTypeId}
                name={roomTypeData.roomTypeName}
                data={roomType}
                onClickAccomodation={onClickAccomodation}
              />
            );
          } )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

const DashboardPMS = ( {
  data,
  onClickAccomodation,
  isLoading,
  onFetchProperties,
  onFetchCities,
  onfetchRoomTypes,
  selectedPropertyId,
  onChangeFilter,
  date,
  onChangeDate,
  setCleaningStatus,
  onFetchTagsPredefined,
  accommodations,
  selectedAccs,
  onAccommodationChange,
} ) => (
  <>
    <SimpleHeader
      title="dashboard"
    />
    <Container
      fluid
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <Card>
        <CardBody>
          <Row className="align-items-center mb-4">
            <Col md={8}>
              <Row>
                <Col xl={selectedPropertyId ? 2 : 3} md={6}>
                  <AsyncSelect
                    label="city"
                    defaultOptions
                    loadOptions={onFetchCities}
                    labelKey={( option ) => _get( option, 'city.name' )}
                    isClearable
                    idKey="city.id"
                    onChange={( option ) => onChangeFilter( 'cityId', _get( option, 'value' ) )}
                  />
                </Col>
                <Col xl={selectedPropertyId ? 2 : 3} md={6}>
                  <AsyncSelect
                    label="property"
                    defaultOptions
                    loadOptions={onFetchProperties}
                    isClearable
                    onChange={( option ) => onChangeFilter( 'propertyId', _get( option, 'value' ) )}
                  />
                </Col>
                {selectedPropertyId && (
                <Col xl={3} md={6}>
                  <AsyncSelect
                    label="roomType"
                    defaultOptions
                    loadOptions={onfetchRoomTypes}
                    isClearable
                    key="commercialName"
                    onChange={( option ) => onChangeFilter( 'roomTypeId', _get( option, 'value' ) )}
                  />
                </Col>
                )}
                <Col xl={selectedPropertyId ? 2 : 3} md={6}>
                  <Select
                    label="type"
                    options={[
                      { id: 'checkin', name: 'checkin' },
                      { id: 'checkout', name: 'checkout' },
                      { id: 'empty', name: 'empty' },
                      { id: 'full', name: 'customer-inside' },
                      { id: 'checkInOut', name: 'checkin-checkout' },
                    ]}
                    isClearable
                    onChange={( value ) => onChangeFilter( 'type', value )}
                  />
                </Col>
                <Col xl={selectedPropertyId ? 2 : 3} md={6}>
                  <Select
                    label="cleaningStatus"
                    options={cleaningStatus}
                    isClearable
                    translateOptions
                    onChange={setCleaningStatus}
                  />
                </Col>
                <Col xl={selectedPropertyId ? 2 : 3} md={6}>
                  <Select
                    label="accommodation"
                    options={accommodations}
                    isClearable
                    multiple
                    defaultOptions
                    value={selectedAccs}
                    onChange={onAccommodationChange}
                  />
                </Col>
                <Col xl={selectedPropertyId ? 2 : 3} md={6}>
                  <AsyncSelect
                    label="tags"
                    defaultOptions
                    loadOptions={onFetchTagsPredefined}
                    multiple
                    formatFn={( dataTagsPredefined ) => (
                      ( dataTagsPredefined || [] ).map(
                        ( item ) => ( { id: item, name: item } ),
                      ) )}
                    onChange={( option ) => onChangeFilter( 'tags', option )}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row className="d-flex align-items-center justify-content-end">
                <Col xs="auto" className="py-4">
                  <Datepicker
                    label="date"
                    dateFormat="ddd, D MMM YYYY"
                    utc
                    formGroupClass="btn-datepicker-custom "
                    labelClass="fc-header-toolbar"
                    placeholder="from"
                    appendIcon={<i className="th-icon-calendar" />}
                    value={date}
                    onChange={onChangeDate}
                    startDateField={{ value: moment().subtract( '1', 'day' ) }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <Row>
                <Statistic
                  title="checkin"
                  value={data.checkin}
                />
                <Statistic
                  title="checkout"
                  value={data.checkout}
                />
                <Statistic
                  title="tasks"
                  value={data.tasksPending}
                />
                <Statistic
                  title="emptyAccom"
                  value={data.empty}
                />
                <Statistic
                  title="accFull"
                  value={data.full}
                />
                <Statistic
                  title="occupancy"
                  value={data.occupancy && `${data.occupancy}  %`}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
          data.properties.map( ( property ) => (
            <PM
              key={property.propertyId}
              name={property.propertyName}
              data={property.data}
              checkout={property.checkout}
              checkin={property.checkin}
              checkInOut={property.checkInOut}
              tasksPending={property.tasksPending}
              tasksClosed={property.tasksClosed}
              empty={property.empty}
              onClickAccomodation={onClickAccomodation}
            />
          ) )
      }
    </Container>
  </>
);
export default DashboardPMS;
