import React, { useState } from 'react';
import { canModify } from 'config/Ability';
import { EditCard, HorizontalMenu, Intl } from 'components';
import {
  Card, Col, Row,
} from 'reactstrap';
import CommunityRule from 'containers/Property/RoomType/Accommodation/Edit/CommunityRule/Component';
import Smarthlockers from 'containers/Property/RoomType/Accommodation/Edit/Smartlockers/Component';
import Wifi from 'containers/Property/RoomType/Accommodation/Edit/Wifi/Component';
import TravelReport from 'containers/Property/RoomType/Accommodation/Edit/TravelReport/Component';

export default React.memo(
  ( {
    name,
    entityId,
    data,
    paths,
    parentIds,
    onReloadData,
    pathLayout,
    tabs,
    pathname,
    extraData,
  } ) => {
    const [activeTab, setactiveTab] = useState( 'communityRules' );
    return (
      <EditCard
        headerContent={(
          <>
            <h3>
              <Intl
                id={
                  canModify( 'accommodation' )
                    ? 'editAccommodation'
                    : 'accommodation'
                }
              />
              {` ${name}`}
            </h3>
          </>
        )}
        cardClass="card-sec-bottom"
      >
        <Card className="card-sec-top border-bottom">
          <HorizontalMenu
            tabs={tabs}
            pathLayout={pathname}
            pathname={pathname}
            selected={activeTab}
            onChangeTab={setactiveTab}
          />
        </Card>
        <Row>
          <Col>
            {activeTab === 'communityRules' ? (
              <CommunityRule
                name={name}
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                onReloadData={onReloadData}
                pathLayout={pathLayout}
                tabs={tabs}
                pathname={pathname}
              />
            ) : null}
            {activeTab === 'smartLocker' ? (
              <Smarthlockers
                name={name}
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                onReloadData={onReloadData}
                pathLayout={pathLayout}
                tabs={tabs}
                pathname={pathname}
                extraData={extraData}
              />
            ) : null}
            {activeTab === 'Wifi' ? (
              <Wifi
                name={name}
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                onReloadData={onReloadData}
                pathLayout={pathLayout}
                tabs={tabs}
                pathname={pathname}
              />
            ) : null}
            {activeTab === 'travelReport' ? (
              <TravelReport
                name={name}
                entityId={entityId}
                data={data}
                paths={paths}
                parentIds={parentIds}
                onReloadData={onReloadData}
                pathLayout={pathLayout}
                tabs={tabs}
                pathname={pathname}
              />
            ) : null}
          </Col>
        </Row>
      </EditCard>
    );
  },
);
