import { Row, Col } from 'reactstrap';
import React from 'react';

import { EditCard, Intl } from 'components';
import wip from 'assets/img/illustrations/wip.svg';

const NewFunctionalityComponent = () => (
  <EditCard cardClass="card-sec-bottom">
    <Row>
      <Col className="coming-soon mt-1">
        <img src={wip} alt={<Intl id="comingSoon" />} />
        <h1><Intl id="newFunctionality" /></h1>
        <p><Intl id="newFunctionalityText" /></p>
      </Col>
    </Row>
  </EditCard>
);

export default NewFunctionalityComponent;
