export default [
  { name: 'grandparent', id: 'Abuelo/a' },
  { name: 'great_grandparent', id: 'Bisabuelo/a' },
  { name: 'cousin', id: 'Primo/a' },
  { name: 'godson', id: 'Ahijado/a' },
  { name: 'great_grandson', id: 'Bisnieto/a"' },
  { name: 'spouse', id: 'Cónyuge' },
  { name: 'brother_in_law', id: 'Cuñado/a' },
  { name: 'sibling', id: 'Hermano/a' },
  { name: 'offspring', id: 'Hijo/a' },
  { name: 'parent', id: 'Padre o madre' },
  { name: 'niece', id: 'Nieto/a' },
  { name: 'nephew', id: 'Sobrino/a' },
  { name: 'parent_in_law', id: 'Suegro/a' },
  { name: 'uncle', id: 'Tío/a' },
  { name: 'son_in_law', id: 'Yerno o nuera' },
  { name: 'tutor', id: 'Tutor/a' },
  { name: 'others', id: 'Otros' },
];
