import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Intl, Select } from 'components';

const hourListOptions = [];
const minuteListOptions = [];

let d = 0;
while ( d < 24 ) {
  hourListOptions.push( {
    text: d < 10 ? `0${d}` : d,
    value: d,
  } );
  // eslint-disable-next-line no-plusplus
  d++;
}
d = 0;
while ( d < 60 ) {
  minuteListOptions.push( {
    text: d < 10 ? `0${d}` : d,
    value: d,
  } );
  // eslint-disable-next-line no-plusplus
  d++;
}

const CostRow = ( {
  dataRow, index, onUpdate, onDelete, disabled,
} ) => {
  const [description, setDescription] = useState( dataRow.description );
  const [cost, setCost] = useState( dataRow.cost );
  const [count, setCount] = useState( dataRow.count || 1 );
  const [disable, setDisable] = useState( dataRow.isExpense || false );
  const [appyTo, setApplyTo] = useState( dataRow.appyTo || null );
  const [appyToInit, setApplyToInit] = useState( null );
  const [rest, setRest] = useState( {} );
  const applyToValues = [
    { name: 'owner', label: 'owner', id: 'owner' },
    {
      name: 'propertyManager',
      label: 'propertyManager',
      id: 'propertyManager',
    },
    { name: 'client', label: 'client', id: 'client' },
  ];

  useEffect( () => {
    if ( dataRow ) {
      const {
        cost: _cost,
        description: _description,
        count: _count,
        applyTo: _applyTo,
        isExpense,
        ..._rest
      } = dataRow;
      setCost( _cost );
      setDescription( _description );
      setCount( _count );
      setDisable( isExpense );
      setApplyTo( _applyTo );
      setApplyToInit( applyToValues.find( ( item ) => item.id === _applyTo ) );
      setRest( { ..._rest, isExpense } );
    }
  }, [dataRow, applyToValues] );

  return (
    <Row className="mb-3 mx--1">
      <Col className="px-1">
        <div className="text-bold text-small">
          <Intl id="description" />
        </div>
        <input
          disabled={disable || disabled}
          type="text"
          className="form-control-sm form-control"
          value={description}
          onChange={( e ) => {
            const v = e.target.value;
            setDescription( v );
            onUpdate( index, v, cost, count, appyTo, rest );
          }}
        />
      </Col>
      <Col xs="auto" className="px-1">
        <div style={{ width: 100 }}>
          <div className="text-bold text-small">
            <Intl id="applyTo" />
          </div>
          <Select
            size="sm"
            value={appyToInit}
            options={applyToValues}
            translateOptions
            isDisabled={disabled}
            onChange={( v ) => {
              setApplyTo( v );
              onUpdate( index, description, cost, count, v, rest );
            }}
          />
        </div>
      </Col>
      <Col xs="auto" className="px-1">
        <div style={{ width: 100 }}>
          <div className="text-bold text-small">
            <Intl id="quantity" />
          </div>
          <input
            type="number"
            disabled={disabled}
            className="form-control-sm form-control"
            value={count}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setCount( v );
              onUpdate( index, description, cost, v, appyTo, rest );
            }}
          />
        </div>
      </Col>
      <Col xs="auto" className="px-1">
        <div style={{ width: 100 }}>
          <div className="text-bold text-small">
            <Intl id="cost" />
          </div>
          <input
            type="number"
            disabled={disabled}
            className="form-control-sm form-control"
            value={cost}
            onChange={( e ) => {
              const v = parseInt( e.target.value, 10 );
              setCost( v );
              onUpdate( index, description, v, count, appyTo, rest );
            }}
          />
        </div>
      </Col>

      {disabled ? null : (
        <Col xs={1} className="px-1 pt-3">
          <div
            className="p-1 text-danger d-inline-block cursor-pointer"
            onClick={() => {
              onDelete( index );
            }}
          >
            <i className="fa fa-times" />
          </div>
        </Col>
      )}
    </Row>
  );
};

/// ///////////////////////////////////////////////

const CostList = ( {
  value = [],
  label,
  onSubmit = () => {},
  showRequiredError,
  disabled,
} ) => {
  const [list, setList] = useState( [] );
  const [enabledUpdate, setEnabledUpdate] = useState( false );

  useEffect( () => {
    if ( value ) {
      setList( value );
    }
  }, [value] );

  const onNew = useCallback( () => {
    const listClone = [...list];
    listClone.push( { description: '', cost: 0 } );
    setList( listClone );
    setEnabledUpdate( true );
  }, [list] );

  const onUpdate = useCallback(
    ( index, description, cost, count, applyTo, rest ) => {
      const listClone = [...list];
      listClone[index] = {
        description,
        cost,
        count,
        applyTo,
        ...rest,
      };
      setList( listClone );
      setEnabledUpdate( true );
    },
    [list],
  );

  const onDelete = useCallback(
    ( index ) => {
      const listClone = [...list.slice( 0, index ), ...list.slice( index + 1 )];
      setList( listClone );
      setEnabledUpdate( true );
    },
    [list],
  );

  return (
    <>
      <div>
        {label && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <label className="font-weight-bold form-control-label mb-2 d-block">
            <Intl id={label} />
          </label>
        )}
        {list.length === 0 && (
          <div className="mb-3">
            <Intl id="noExtraExpenses" />
          </div>
        )}

        {list.map( ( dataRow, k ) => (
          <CostRow
            disabled={disabled}
            dataRow={dataRow}
            index={k}
            // eslint-disable-next-line react/no-array-index-key
            key={k}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ) )}
        {disabled ? null : (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <a
            href="#"
            className="text-bold d-block mb-3"
            onClick={( e ) => {
              e.preventDefault();
              onNew();
            }}
          >
            +
            {' '}
            <Intl id="addExtraCost" />
          </a>
        )}
        {enabledUpdate && !disabled && (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setEnabledUpdate( false );
              onSubmit( list );
            }}
          >
            <Intl id="updateExtraCosts" />
          </Button>
        )}
        {list.length === 0 && showRequiredError ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            <Intl id="fieldError.required" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CostList;
