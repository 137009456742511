import _ from 'lodash';
import React from 'react';
import SettingsActions from 'store/reducers/Settings';
import { connect } from 'react-redux';
import RUInfoView from 'views/Property/RoomType/Accommodation/Edit/RUInfo';
import NewFunctionalityComponent from 'containers/NewFuntionality/Component';
import ruInfoRoutes from './ruInfoRoutes';

const RUInfo = ( {
  data, entityId, paths, parentIds, onReloadData, pathLayout,
  pathname, ruInstalled,
} ) => {
  if ( !ruInstalled ) {
    return <NewFunctionalityComponent />;
  }
  return (
    <RUInfoView
      name={data.entity ? data.entity.name : ''}
      data={data}
      entityId={entityId}
      paths={paths}
      parentIds={parentIds}
      onReloadData={onReloadData}
      pathLayout={pathLayout}
      pathname={pathname}
      tabs={ruInfoRoutes}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

const mapStateToProps = ( { user } ) => ( {
  ruInstalled: _.get( user, 'settings.ruInstalled', false ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( RUInfo );
