import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DashboardView from 'views/PropertyManager/Edit/Occupancies/Dashboard';
import SettingsActions from 'store/reducers/Settings';
import { getAllOccupancys, deleteOccupancy } from 'api/Occupancy';
import { useDelete, useFetchTableData } from 'hooks';
import AccommodationService from 'api/Accommodation';
import NewFunctionality from 'containers/NewFuntionality';
import NewModal from '../New';
import EditDrawer from '../EditDrawer';

const Dashboard = ( {
  toggleErrorAlert, toggleLoading, propertyManager, ruInstalled,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [modalOpen, setModalOpen] = useState( false );
  const [selectedId, setSelectedId] = useState();

  const [drawerOpen, setDrawerOpen] = useState( false );

  const onOpenModal = () => {
    setModalOpen( true );
  };

  const onCloseModal = () => {
    setModalOpen( false );
  };

  const onOpenDrawer = ( id ) => {
    setSelectedId( id );
    setDrawerOpen( true );
  };

  const onCloseDrawer = () => {
    setSelectedId();
    setDrawerOpen( false );
  };

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => getAllOccupancys( { ...params, propertyManagerId: propertyManager.id } ),
    toggleErrorAlert,
    deps: [dataSubmitted],
    conditional: ruInstalled,
  } );

  const reloadData = useCallback( () => {
    onCloseModal();
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const onDeleteItem = useDelete( {
    promise: ( itemId ) => deleteOccupancy( itemId ),
    callback: () => {
      setDataSubmitted( new Date().valueOf() );
    },
    toggleLoading,
    toggleErrorAlert,
  } );

  if ( !ruInstalled ) {
    return <NewFunctionality />;
  }

  return (
    <>
      <DashboardView
        isDataLoading={dataLoading}
        data={data}
        onFetchAccommodations={fetchAccommodations}
        onFetchData={fetchData}
        onOpenEditItemModal={onOpenDrawer}
        onOpenNewItemModal={onOpenModal}
        onDeleteItem={onDeleteItem}
      />
      <NewModal
        occupancyId={selectedId}
        onFetchAccommodations={fetchAccommodations}
        open={modalOpen}
        openDrawer={onOpenDrawer}
        onClose={onCloseModal}
        onReloadData={reloadData}
      />
      <EditDrawer
        onOpenModal={onOpenModal}
        occupancyId={selectedId}
        open={drawerOpen}
        onClose={onCloseDrawer}
        onReloadOccupancies={reloadData}
      />
    </>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  ruInstalled: _.get( user, 'settings.ruInstalled', false ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
