import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import {
  ModalForm, TextField, Select, Datepicker, Dropzone,
} from 'components';
import { required } from 'config/InputErrors';
import countries from 'country-list';
import { Row, Col } from 'reactstrap';
import PhoneField from 'components/Form/Phone';
import RelationTypes from 'config/Constants/RelationTypes';
import DocumentTypes from 'config/Constants/DocumentTypes';

const supportArrayValidation = ['DNI_español', 'Permiso_residencia_español'];

const needSupportDoc = ( doc = '' ) => {
  const isNeccesary = supportArrayValidation.find( ( item ) => item === doc );
  return Boolean( isNeccesary );
};
const GuestModalForm = ( {
  modalOpened,
  handleClose,
  onSubmit,
  selectedGuest,
  loadingGuest,
  isOwner,
} ) => (
  <ModalForm
    initialValues={selectedGuest}
    title={_isEmpty( selectedGuest ) ? 'newGuest' : 'editGuest'}
    showErrorsInFooter
    isLoading={loadingGuest}
    open={modalOpened}
    onClose={handleClose}
    bodyClassName="overflow-visible"
    onSubmit={onSubmit}
    body={( form ) => {
      const documentType = form.getFieldState( 'documentType' )
        && form.getFieldState( 'documentType' ).value;
      return (
        <>
          <Row>
            <Col xs={12} lg={4}>
              <Dropzone
                field="selfie"
                formGroupClass="dz-profile-img"
                label="selfie"
                validate={required}
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                  </>
                )}
              />
            </Col>
            <Col lg={8}>
              <TextField label="firstName" field="name" validate={required} />
              <TextField
                label="lastName"
                field="firstLastname"
                validate={required}
              />
              <TextField label="secondLastname" field="secondLastname" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                field="sex"
                label="sex"
                isClearable
                labelClass="font-weight-bold mr-2"
                translateOptions
                options={[
                  { id: 'F', name: 'female' },
                  { id: 'M', name: 'male' },
                ]}
                validate={required}
              />
            </Col>
            <Col>
              <Datepicker
                field="birthdate"
                label="birthdate"
                dateFormat="DD/MM/YYYY"
                utc
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="locality" field="locality" />
            </Col>
            <Col>
              <TextField label="address" field="address" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="postalCode" field="postalCode" />
            </Col>
            <Col>
              <PhoneField label="phone" field="phone" />
            </Col>
          </Row>
          {isOwner ? (
            <Row>
              <Col>
                <Select
                  field="relationshipWithOwner"
                  label="relation_with_owner"
                  isClearable
                  labelClass="font-weight-bold mr-2"
                  translateOptions
                  options={RelationTypes}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <Select
                field="documentType"
                label="documentType"
                isClearable
                labelClass="font-weight-bold mr-2"
                translateOptions
                options={DocumentTypes}
                validate={required}
              />
            </Col>
            <Col>
              <TextField
                label="documentNumber"
                field="documentNumber"
                validate={required}
              />
            </Col>
            {needSupportDoc( documentType ) ? (
              <Col>
                <TextField
                  label="supportDocumentNumber"
                  field="supportDocumentNumber"
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col>
              <Select
                field="nationality"
                label="nationality"
                isClearable
                labelClass="font-weight-bold mr-2"
                translateOptions
                idKey="code"
                options={countries.getData()}
                validate={required}
              />
            </Col>
            <Col>
              <Datepicker
                field="expeditionDate"
                label="expeditionDate"
                dateFormat="DD/MM/YYYY"
                utc
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <Dropzone
                field="frontalId"
                formGroupClass="dz-profile-img"
                label="frontalId"
                validate={required}
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                  </>
                )}
              />
            </Col>
            <Col xs={12} lg={4}>
              <Dropzone
                field="reverseId"
                formGroupClass="dz-profile-img"
                label="reverseId"
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                  </>
                )}
              />
            </Col>
            <Col xs={12} lg={4}>
              <Dropzone
                field="signature"
                validate={required}
                formGroupClass="dz-profile-img"
                label="signature"
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                  </>
                )}
              />
            </Col>
          </Row>
        </>
      );
    }}
  />
);

export default GuestModalForm;
