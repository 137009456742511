export default [
  { id: 'not_paid', name: 'not_paid' },
  { id: 'half_paid', name: 'half_paid' },
  { id: 'full_paid', name: 'full_paid' },
  { id: 'over_paid', name: 'over_paid' },
  { id: 'cancelled', name: 'cancelled' },
];

export const travelReportStatuses = [
  { id: 'PENDING', name: 'pending', textClass: 'text-gray-1' },
  { id: 'PENDING_VALIDATION', name: 'pending_validation', textClass: 'text-yellow-3' },
  { id: 'APPROVED', name: 'approved', textClass: 'text-success' },
  { id: 'COMPLETE', name: 'complete', textClass: 'text-success' },
  { id: 'REJECTED', name: 'rejected', textClass: 'text-red-1' },
  { id: 'SUBMITED', name: 'submited', textClass: 'text-blue-1' },
  { id: 'NOT_REQUIRED', name: 'not_required', textClass: 'text-gray-1' },
];
