import React, { useState } from 'react';
import { Intl } from 'components';
import classnames from 'classnames';
import Drawer from 'rc-drawer';
import _map from 'lodash/map';
import _get from 'lodash/get';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  CardFooter,
  Collapse,
} from 'reactstrap';

import formatBookinglId from 'utils/bookingId';
import { travelReportStatuses } from 'config/Constants/Booking';
import TaskDetail from '../TaskDetail';

export default ( {
  open,
  onClose,
  onOpenTaskEditModal,
  data,
  isLoading,
  userBookingReference,
} ) => {
  const [showCheinInfo, setShowCheinInfo] = useState( true );
  const toggleCheinInfo = () => setShowCheinInfo( ( state ) => !state );
  const [showCheOutData, setShowCheOutData] = useState( true );
  const toggleCheoutInfo = () => setShowCheOutData( ( state ) => !state );
  const [tasksInfoOpen, setTasksInfoOpen] = useState( true );
  const toggleTasksInfo = () => setTasksInfoOpen( ( state ) => !state );
  return (
    <Drawer
      open={open}
      placement="right"
      handler={false}
      level={null}
      onClose={onClose}
      width={320}
      className={classnames( { 'app-component-loading': isLoading } )}
    >
      <Card className="d-flex flex-column flex-grow-1 flex-shrink-1 mb-0 overflow-hidden h-100">
        <CardHeader>
          {data.name}
          <br />
          {data.cleaningStatus && (
            <>
              <span>
                <i className="fas fa-broom  text-primary" />
              </span>
              <span style={{ fontSize: 12 }}>
                {`Cleaning status:  ${data.cleaningStatus}`}
              </span>
            </>
          )}
        </CardHeader>
        <CardBody className="overflow-auto">
          {data.checkOutData && (
            <>
              <div className="accordion">
                <Row
                  role="tab"
                  onClick={toggleCheoutInfo}
                  aria-expanded={showCheOutData}
                >
                  <Col className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <span>
                        <i className="fas fa-arrow-circle-left text-primary" />
                      </span>
                      <span className="font-weight-bold text-primary text-2xsm text-uppercase ml-2">
                        <Intl id="checkout" />
                      </span>
                    </div>
                    <i
                      className={classnames( 'fa p-2', {
                        'fa-chevron-down': showCheOutData,
                        'fa-chevron-up': !showCheOutData,
                      } )}
                    />
                  </Col>
                </Row>

                <Collapse role="tabpanel" isOpen={showCheOutData}>
                  <hr className="divider mt-1 mb-3" />
                  <Row>
                    <Col>
                      <div>
                        <Intl id="booking" />
                        {' '}
                        <span className="small text-bold">
                          {formatBookinglId( {
                            ...data.checkOutData,
                            userBookingReference,
                          } )}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <a
                        className="font-weight-bold text-primary text-2xsm text-uppercase"
                        href={`/pms/bookings/list/${_get(
                          data,
                          'checkOutData.id',
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Intl id="toBooking" />
                      </a>
                    </Col>
                  </Row>
                  <Row className="text-xsm">
                    <Col>
                      <span>
                        <Intl id="checkin" />
                        {': '}
                        <span
                          className={
                            data.checkOutData.checkinComplete
                              ? 'text-success'
                              : 'text-red-1'
                          }
                        >
                          <Intl
                            id={
                              data.checkOutData.checkinComplete
                                ? 'completed'
                                : 'pending'
                            }
                          />
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row className="text-xsm">
                    <Col>
                      <span>
                        <Intl id="travelReport" />
                        {': '}
                        <span
                          className={
                            data.checkOutData.travelReport
                            && travelReportStatuses.find(
                              ( item ) => item.id === data.checkOutData.travelReport,
                            ).textClass
                          }
                        >
                          <Intl id={data.checkOutData.travelReport
                            && data.checkOutData.travelReport.toLowerCase()}
                          />
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between text-xsm">
                    <div className="d-flex flex-column">
                      <span>
                        <Intl id="customer" />
                      </span>
                      <span className="text-gray">
                        {`${_get(
                          data,
                          'checkOutData.owner.firstName',
                          '',
                        )} ${_get(
                          data,
                          'checkOutData.owner.lastName',
                          '',
                        )}`}
                      </span>
                    </div>
                    <span>
                      <i className="app-icon-support text-lg" />
                    </span>
                  </div>
                  <div className="d-flex text-xsm">
                    <div className="d-flex flex-column mr-2">
                      <Intl id="checkout" />
                      <span className="text-gray">
                        {_get( data, 'checkOutData.checkOutTime' )}
                      </span>
                    </div>
                    <div className="d-flex flex-column ">
                      <Intl id="guests" />
                      <span className="text-gray">
                        {_get( data, 'checkOutData.guests', [] ).length}
                      </span>
                    </div>
                  </div>
                </Collapse>
              </div>
              <hr className="divider" />
            </>
          )}

          {data.checkInData && (
            <>
              <div className="accordion">
                <Row
                  role="tab"
                  onClick={toggleCheinInfo}
                  aria-expanded={showCheinInfo}
                >
                  <Col className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <span>
                        <i className="fas fa-arrow-circle-right text-primary" />
                      </span>
                      <span className="font-weight-bold text-primary text-2xsm text-uppercase ml-2">
                        <Intl id="checkin" />
                      </span>
                    </div>
                    <i
                      className={classnames( 'fa p-2', {
                        'fa-chevron-down': showCheinInfo,
                        'fa-chevron-up': !showCheinInfo,
                      } )}
                    />
                  </Col>
                </Row>
                <Collapse role="tabpanel" isOpen={showCheinInfo}>
                  <hr className="divider mt-1 mb-3" />
                  <Row>
                    <Col>
                      <div>
                        <Intl id="booking" />
                        {' '}
                        <span className="small text-bold">
                          {formatBookinglId( {
                            ...data.checkInData,
                            userBookingReference,
                          } )}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <a
                        className="font-weight-bold text-primary text-2xsm text-uppercase"
                        href={`/pms/bookings/list/${_get(
                          data,
                          'checkInData.id',
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Intl id="toBooking" />
                      </a>
                    </Col>
                  </Row>
                  <Row className="text-xsm">
                    <Col>
                      <span>
                        <Intl id="checkin" />
                        {': '}
                        <span
                          className={
                            data.checkInData.checkinComplete
                              ? 'text-success'
                              : 'text-red-1'
                          }
                        >
                          <Intl
                            id={
                              data.checkInData.checkinComplete
                                ? 'completed'
                                : 'pending'
                            }
                          />
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row className="text-xsm">
                    <Col>
                      <span>
                        <Intl id="travelReport" />
                        {': '}
                        <span
                          className={
                            data.checkInData.travelReport
                            && travelReportStatuses.find(
                              ( item ) => item.id === data.checkInData.travelReport,
                            ).textClass
                          }
                        >
                          <Intl id={data.checkInData.travelReport
                            && data.checkInData.travelReport.toLowerCase()}
                          />
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <hr className="divider mt-1 mb-3" />
                  <div className="d-flex justify-content-between text-xsm">
                    <div className="d-flex flex-column">
                      <span>
                        <Intl id="customer" />
                      </span>
                      <span className="text-gray">
                        {`${_get(
                          data,
                          'checkInData.owner.firstName',
                          '',
                        )} ${_get(
                          data,
                          'checkInData.owner.lastName',
                          '',
                        )}`}
                      </span>
                    </div>
                    <span>
                      <i className="app-icon-support text-lg" />
                    </span>
                  </div>
                  <div className="d-flex text-xsm">
                    <div className="d-flex flex-column mr-2">
                      <Intl id="checkin" />
                      <span className="text-gray">
                        {_get( data, 'checkInData.checkInTime' )}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <Intl id="guests" />
                      <span className="text-gray">
                        {_get( data, 'checkInData.guests', [] ).length}
                      </span>
                    </div>
                  </div>
                </Collapse>
              </div>
              <hr className="divider" />
            </>
          )}

          {data.tasks && data.tasks.length > 0 && (
            <>
              <div className="accordion">
                <Row
                  role="tab"
                  onClick={toggleTasksInfo}
                  aria-expanded={tasksInfoOpen}
                >
                  <Col className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <span>
                        <i className="ni ni-bullet-list-67 text-primary" />
                      </span>
                      <span className="font-weight-bold text-primary text-2xsm text-uppercase ml-2">
                        <Intl id="tasksIssues" />
                      </span>
                    </div>
                    <i
                      className={classnames( 'fa p-2', {
                        'fa-chevron-down': tasksInfoOpen,
                        'fa-chevron-up': !tasksInfoOpen,
                      } )}
                    />
                  </Col>
                </Row>

                <Collapse role="tabpanel" isOpen={tasksInfoOpen}>
                  <hr className="divider mt-1 mb-3" />
                  {_map( data.tasks, ( task ) => (
                    <div key={task.id}>
                      <TaskDetail
                        task={task}
                        onOpenTaskEditModal={onOpenTaskEditModal}
                      />
                      <hr className="divider my-3" />
                    </div>
                  ) )}
                </Collapse>
              </div>
            </>
          )}
        </CardBody>
        <CardFooter className="chat-create-channel">
          <Button
            className="btn btn-outline-link"
            onClick={() => onOpenTaskEditModal()}
          >
            <i className="app-icon-warning" />
            <span>
              <Intl id="reportIssue" />
            </span>
          </Button>
        </CardFooter>
      </Card>
    </Drawer>
  );
};
